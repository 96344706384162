<template>
  <div>
    <div style="margin-bottom:20px;">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item >强算子解读</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <el-card shadow="always">
      <div class="title">信息筛选</div>
      <div class="search-content">
        <div style="display:flex;">
          <div style="display:flex;align-items:center;">
            <div style="width:100px;">强算子标题</div>
            <el-input v-model.trim="policyTitle" placeholder="请输入强算子标题关键字"/>
          </div>
          <div style="display:flex;align-items:center;margin-left:80px;">
            <div style="width:75px;">发布日期</div>
            <el-date-picker value-format="YYYY-MM-DD" v-model="publishDate" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
            </el-date-picker>
          </div>
        </div>
        <div class="search-btn" @click="search">筛选</div>
      </div>
    </el-card>
    <el-card shadow="always" class="list-container">
      <div class="header">
        <div class="header__title">信息列表</div>
        <div class="header__btn" @click="addPolicy">新增强算子解读</div>
      </div>
      <div class="content">
        <el-table :data="tableData" :header-cell-style="{'text-align': 'center','font-weight': 'bold',color: '#333'}" :cell-style="{'text-align': 'center',color: '#333'}" style="width: 100%">
          <el-table-column prop="id" label="强算子编号" />
          <el-table-column prop="title" label="强算子标题" />
          <el-table-column prop="releaseDate" label="发布日期" :formatter="formatterTime"/>
          <el-table-column prop="interpretationTime" label="解读时间" />
          <el-table-column prop="interpretationMan" label="解读人" />
          <el-table-column label="操作">
            <template #default="scope">
              <el-button @click="showDetail(scope.row)" type="text" size="small">详情</el-button>
            </template>
          </el-table-column>
        </el-table>

        <div class="page">
          <el-pagination v-model:currentPage="currentPage" :page-sizes="[5, 10, 20, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange">
          </el-pagination>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { reactive, toRefs, getCurrentInstance, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
export default {
  setup() {
    const { proxy } = getCurrentInstance();
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      policyTitle: '',
      publishDate: [],
      currentPage: 1,
      pageSize: 10,
      tableData: [],
      total: 0
    })

    onMounted(() => {
      getList()
    })

    const search = () => {
      getList()
    }

    const getList = () => {
      let data = {
        endDate: state.publishDate ? state.publishDate[1] || '' : '',
        page: {
          pageNo: state.currentPage,
          pageSize: state.pageSize
        },
        startDate: state.publishDate ? state.publishDate[0] || '' : '',
        title: state.policyTitle
      }
      proxy.$post('/cts/back/policy/page', data).then((res) => {
        if (res.resultState === '200') {
          state.tableData = res.data.list;
          state.total = res.data.total;
        }
      }).catch((err) => {

      });
    }

    const handleSizeChange = (val) => {
      state.pageSize = val;
      getList();
    }

    const handleCurrentChange = (val) => {
      state.currentPage = val;
      getList();
    }

    const addPolicy = () => {
      router.push({ path: '/content/add' })
    }

    const showDetail = (data) => {
      router.push({ path: '/content/detail', query: {id: `${data.id}`} })
    }

    const formatterTime = (row, column) => {
      let time = row.releaseDate;
      let month = '', date = '';
      if (time[1] < 10) {
        month = `0${time[1]}`
      } else {
        month = `${time[1]}`
      }
      if (time[2] < 10) {
        date = `0${time[2]}`
      } else {
        date = `${time[2]}`
      }

      return `${time[0]}-${month}-${date}`
    }

    return {
      ...toRefs(state),
      search,
      handleSizeChange,
      handleCurrentChange,
      addPolicy,
      showDetail,
      formatterTime
    }
  }
}
</script>

<style lang="scss" scoped>
.container-title {
  font-size: 16px;
  font-weight: bold;
  margin: 10px 0 20px 0;
}
.title {
  font-weight: bold;
}
.search-content {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .search-box {
    display: flex;
    align-items: center;
    &__title {
      width: 130px;
    }
  }
  .search-btn {
    background: rgb(56, 162, 138);
    width: 80px;
    padding: 8px 0;
    border-radius: 6px;
    text-align: center;
    color: #fff;
    cursor: pointer;
    font-weight: bold;
  }
}
.list-container {
  margin-top: 20px;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    &__title {
      font-weight: bold;
    }
    &__btn {
      background: rgb(56, 162, 138);
      width: 110px;
      padding: 8px 0;
      border-radius: 6px;
      text-align: center;
      color: #fff;
      cursor: pointer;
      font-weight: bold;
    }
  }
  .content {
    margin-top: 15px;
    .page {
      float: right;
      margin: 20px;
    }
  }
}
</style>